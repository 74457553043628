<template>
  <v-data-table
    :mobile-breakpoint="0"
    :headers="adminTableHeaders"
    :items="orders"
    :search="$store.state.search"
    :hide-default-footer="api_paginated"
    :disable-pagination="api_paginated"
  >
    <template v-slot:[`item.id`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }">{{
        item.id || "--"
      }}</router-link>
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }">
        <span v-html="item.title"></span>
      </router-link>
    </template>

    <template v-slot:[`item.client_name`]="{ item }">
      <router-link
        v-if="item.client_id"
        :to="{ name: 'ClientProfile', params: { id: item.client_id } }"
        >{{ item.client_name || "--" }}</router-link
      >
    </template>

    <template v-slot:[`item.editor_name`]="{ item }">
      <router-link
        v-if="item.writer_id && item.editor_name"
        :to="{ name: 'WriterProfile', params: { id: item.writer_id || '' } }"
        >{{ item.editor_name || "--" }}</router-link
      >
    </template>

    <template v-slot:[`item.writer_name`]="{ item }">
      <router-link
        v-if="item.writer_id && item.writer_name"
        :to="{ name: 'WriterProfile', params: { id: item.writer_id || '' } }"
        >{{ item.writer_name || "--" }}</router-link
      >
    </template>

    <template v-slot:[`item.writer_cost`]="{ item }">
      {{ item.writer_cost ? `$${item.writer_cost}` : "" }}
    </template>

    <template v-slot:[`item.urgency_text`]="{ item }">
      <span
        style="min-width: 50px !important"
        :class="item.urgency_text.past_due ? 'text-danger' : 'text-success'"
      >
        {{ item.urgency_text.date || "--" }}
      </span>
    </template>

    <template v-slot:[`item.client_deadline`]="{ item }">
      <span
        style="min-width: 50px !important"
        :class="item.client_deadline.past_due ? 'text-danger' : 'text-success'"
      >
        {{ item.client_deadline.date || "--" }}
      </span>
    </template>

    <template v-slot:[`item.editor_deadline`]="{ item }">
      <span
        style="min-width: 50px !important"
        :class="item.editor_deadline.past_due ? 'text-danger' : 'text-success'"
      >
        {{ item.editor_deadline.date || "--" }}
      </span>
    </template>

    <template v-slot:[`item.writer_deadline`]="{ item }">
      <span
        style="min-width: 50px !important"
        :class="item.writer_deadline.past_due ? 'text-danger' : 'text-success'"
      >
        {{ item.writer_deadline.date || "--" }}
      </span>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        elevation="0"
        :to="{ name: 'Order', params: { id: item.id } }"
        fab
        x-small
      >
        <v-icon color="primary" small>mdi-eye</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import { mapState } from "vuex";
export default {
  props: {
    orders: {
      type: Array,
    },
    api_paginated: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("orders", ["adminTableHeaders"]),
  },
};
</script>